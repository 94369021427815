import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroPages from "../components/heroPages"
import Image from "../components/imagesComponent/corporate"
import { graphql } from "gatsby"
import ImageService from "../components/imagesComponent/services/chairCorporate"

export default function Corporate({ data }) {
  const { barText } = data.takeshape.getTopBar
  const footer = data.takeshape.getContactUsPage
  const { title, subtitle, list } = data.takeshape.getCorporatePage
  return (
    <Layout footer={footer} barText={barText}>
      <SEO title="Coporate" />
      <HeroPages title={title} text={subtitle} image={<Image />} />
      <div className="container mx-auto px-5 grid md:grid-cols-2 md:gap-10 md:mt-16 md:mb-24 mb-10">
        <div className="bg-primary overflow-hidden shadow-xl w-full rounded-lg h-64">
          <ImageService />
        </div>
        <div>
          <h2 className="text-5xl text-primary lg:mt-0 mt-5 font-serif">
            {list[0].service.name}
          </h2>
          <p className="leading-loose mb-4">{list[0].service.description}</p>
          <div className="flex flex-wrap">
            {list[0].options.map(({ timeAndPrice }, index) => (
              <a
                key={index}
                className="py-3 px-5 text-orange-900 bg-orange-200 rounded-full hover:bg-orange-300 whitespace-no-wrap mr-3"
                href={`https://api.whatsapp.com/send?phone=+17863269958&text=Service Type: Corporate | Service: ${list[0].service.name} | Option: ${timeAndPrice}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {timeAndPrice}
              </a>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($locale: String) {
    takeshape {
      getCorporatePage(locale: $locale) {
        title
        subtitle
        list {
          options {
            timeAndPrice
          }
          service {
            name
            description
          }
        }
      }
      getContactUsPage {
        phones {
          phone
        }
        facebook
        instagram
      }
      getTopBar(locale: $locale) {
        barText
      }
    }
  }
`
